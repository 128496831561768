import React from 'react';
import Head from "next/head";
import { NextSeo } from "next-seo";
import { seoFunct } from "../../config/next-seo.config.js";
import { withRouter } from "next/router";
import HomeNav from './HomeNav.jsx';
import HeroSection from './HeroSection.jsx';
import HomePartners from './HomePartners.jsx';
import HomeStatistics from './HomeStatistics.jsx';
import HomeEventTicketingIntro from './HomeEventTicketingIntro.jsx';
import HomeEventBrand from './HomeEventBrand.jsx';
import HomeEventManagementIntro from './HomeEventManagementIntro.jsx';
import HomeCategoriesHighlight from './HomeCategoriesHighlight.jsx';
import HomePricingPlans from './HomePricingPlans.jsx';
import HomeFooter from './HomeFooter.jsx';
import HomeFeedback from './HomeFeedback.jsx';
import HeroFeatureTabs from './HeroFeatureTabs.jsx';
import HeroFeatureAccordion from './HeroFeatureAccordion.jsx';
import HomeBottomCta from './HomeBottomCta.jsx';
import DataPrivacySection from './DataPrivacySection.jsx';

const Home = () => {
  return (
    <div className="main-container font-figtree">
      <NextSeo {...seoFunct("homeSeo")} />
      <HomeNav isBgNavyBlue={false} isHomePage={true} />
      <HeroSection />
      <HomePartners />
      <HomeStatistics />
      <HeroFeatureTabs />
      <HeroFeatureAccordion />
      <HomeEventTicketingIntro />
      <HomeEventBrand />
      <HomeEventManagementIntro />
      <HomeCategoriesHighlight />
      <HomeFeedback />
      <HomePricingPlans ishomePage={'ishomePage'} />
      <HomeBottomCta />
      <DataPrivacySection />
      <HomeFooter />
      <style jsx>{`
        .main-container {
          width: 100%;
        }
      `}</style>
    </div>
  );
};

export default withRouter(Home);