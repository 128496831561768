import React from 'react';
import revampData from '../../data/revampData.json';
import { theme, websiteColors } from "../../themes/Theme.js";

const HomeEventManagementIntro = () => {
  const intros = revampData.data.listEventManagementIntros.data;


  return (
    <div>
      <div className="px-sm-5 font-figtree px-3 text-center py-5 m-auto"  style={{ maxWidth: '940px' }}>
        <h6 className="text-uppercase heading-label mb-2 m-auto" >{intros[2].title}</h6>
        <h1 className="display-5 main-heading m-auto" style={{ maxWidth: '600px' }}>{intros[1].title}</h1>
        <p className="lead main-sub-heading mt-3">{intros[0].title}</p>
      </div>

      <style jsx>{`
        .container {
          width: 100%;
        }

        .heading-label {
          color: ${websiteColors[theme].primaryWebsiteTheme};
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }

        .main-sub-heading {
          color:${websiteColors[theme].textSecondary};
          font-size: 24px;
          font-weight: 400;
          line-height: 30px;
        }

        .main-heading {
          color: #001a47;
          font-size: 48px;
          font-weight: 600;
          line-height: 57.6px;
          letter-spacing: -2px;
        }

        @media (max-width: 576px) {
          .main-heading {
            font-size: 32px;
            font-weight: 600;
            line-height: 38.4px;
          }

          .main-sub-heading {
            font-size: 20px;
          }
        }
      `}</style>
    </div>
  );
};

export default HomeEventManagementIntro;
