import React, { useState, useEffect } from "react";
import revampData from '../../data/revampData.json';
import { theme, websiteColors } from "../../themes/Theme.js";

const testimonialsData = revampData.data.listTestimonialSections.data.map(testimonial => ({
  description: testimonial.testimonialContent.description,
  author: testimonial.testimonialContent.author,
  role: testimonial.testimonialContent.role,
  userImage: testimonial.testimonialContent.userImage,
}));

const TestimonialCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
    }, 3000); // Change testimonial every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const handleStepClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="testimonial-carousel-container font-figtree">
      {/* Progress bar with steps */}
      <div className="progress-bar-container">
        {testimonialsData.map((_, index) => (
          <div
            key={index}
            className={`progress-bar-step ${index === activeIndex ? "active" : ""
              }`}
            onClick={() => handleStepClick(index)}  // Handle click event
            style={{ cursor: 'pointer' }}  // Add pointer cursor for clickable effect
          ></div>
        ))}
      </div>

      <div className="testimonial-content">
        <p className="testimonial-description">
          {testimonialsData[activeIndex].description}
        </p>
        <div className="testimonial-author">
          <div className="d-flex gap-3 align-items-center">
            <img style={{ width: "60px", height: "60px", border: "1px solid #002E6E", borderRadius: "188px" }} src={testimonialsData[activeIndex].userImage} alt="" />
            <div>
              <strong className="mb-3 author">
                {testimonialsData[activeIndex].author}
              </strong>
              <div className="role">
                {testimonialsData[activeIndex].role}
              </div>
            </div>
          </div>

        </div>
      </div>

      <style jsx>{`
        .testimonial-carousel-container {
          max-width: 600px;
          margin: auto;
          padding: 10px 20px;
          border-radius: 10px;
        }
        .role {
          font-size: 14px;
          font-weight: 400;
        }

        .author {
          font-weight: 600 !important;
        }

        .progress-bar-container {
          display: flex;
          margin-bottom: 20px;
        }

        .progress-bar-step {
          width: 150px;
          height: 5px;
          background-color: #e5e5e5;
          margin: 0 5px 0 0;
        }

        .progress-bar-step.active {
          background-color: ${websiteColors[theme].primaryWebsiteTheme};
        }

        .testimonial-description {
          font-size: 16px;
          line-height: 27px;
          margin-bottom: 20px;
          color: ${websiteColors[theme].textParagraph};
          font-weight: 500;
        }

        .testimonial-author {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: ${websiteColors[theme].textParagraph};
        }

        @media (max-width: 990px) {
          .progress-bar-step {
            min-width: 50px;
            width: 100%;
            height: 5px;
            margin: 0 5px 0 0;
          }
        }

        @media (max-width: 768px) {
          .testimonial-carousel-container {
            padding: 10px 5px;
          }
        }

        @media (max-width: 576px) {
          .testimonial-author {
            font-size: 16px;
            font-weight: 600;
          }
        }

        @media (max-width: 374px) {
          .progress-bar-step {
            width: 41px;
          }
        }
        @media (max-width:477px) {
          .progress-bar-step {
            min-width: 45px;
            width:auto !important;
          }
        }
        @media (max-width:426px) {
          .progress-bar-step {
            min-width: 35px;
            width:auto !important;
          }
        }
      `}</style>
    </div>
  );
};

export default TestimonialCarousel;