import React from 'react';
import revampData from '../../data/revampData.json';
import { theme, websiteColors } from "../../themes/Theme.js";


const HomeCategoriesHighlight = () => {
  const categories = revampData.data.listEventCategoriesHighlights.data;


  return (
    <div className="px-3 font-figtree pt-1">
      <div className="px-sm-5 d-flex flex-column-reverse px-3 py-lg-4 py-0 pb-3">
        {categories.map((category, index) => (
          <div className="row mb-5 category-card" key={category.id}>
            <div
              className={`col-lg-6 category-txt-container d-flex align-items-center order-1 order-lg-${
                index % 2 === 0 ? '1' : '2'
              }`}
            >
              <div className="text-sm-center">
                <h3 className="fw-bold category-heading">{category.highlight.title}</h3>
                <p className="category-desc">{category.highlight.subTitle}</p>
              </div>
            </div>

            <div
              className={`col-lg-6 order-2 img-h-container order-lg-${
                index % 2 === 0 ? '2' : '1'
              }`}
              style={{ maxWidth: '500px', maxHeight: '390px', margin: 'auto' }}
            >
              <img
                src={category.highlight.highlightImage}
                alt={category.highlight.title}
                className="img-fluid rounded"
              />
            </div>
          </div>
        ))}
      </div>
      <style jsx>{`
        .category-card {
          border: 1px solid #61c0ff;
          border-radius: 5px;
        }
        .img-fluid{
         // height: 100%;
        }
        .category-desc {
          font-weight: 400;
          font-size: 20px;
          line-height: 34px;
          color:${websiteColors[theme].textParagraph};
        }
        .category-heading {
          font-weight: 600;
          font-size: 32px;
          line-height: 38.4px;
          color: #001234;
        }
        @media screen and (max-width: 992px) {
          .category-txt-container {
            padding: 32px 16px !important;
          }
          .img-h-container {
            max-height: 400px !important;
            padding: 32px 16px !important;
          }
        }
      `}</style>
    </div>
  );
};

export default HomeCategoriesHighlight;
