import React from 'react';
import revampData from '../../data/revampData.json';
import { theme, websiteColors } from "../../themes/Theme.js";

const HomeEventTicketingIntro = () => {
  // const introData = eventIntroData.data.listEventTicketingIntros.data;
  const introData = revampData.data.listEventTicketingIntros.data;

  return (
    <div>
      <div className="px-sm-5 font-figtree px-3 text-center mt-sm-2 mt-3 py-5">
        {/* <h6 className="text-uppercase heading-label mb-3">{introData[0].title}</h6> */}
        <h1 className="display-5 main-heading">Why Choose KonfHub?</h1>
        <p className="lead main-sub-heading mt-3">Extensive Features to Ensure Seamless Experience for Organizers & Attendees</p>
      </div>
      <style jsx>{`
        .heading-label {
          color: ${websiteColors[theme].primaryWebsiteTheme};
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }

        .main-sub-heading {
          color: ${websiteColors[theme].textSecondary};
          font-size: 24px;
          font-weight: 400;
          line-height: 30px;
        }

        .main-heading {
          color: #001a47 !important;
          font-size: 48px;
          font-weight: 600;
          line-height: 57.6px;
          letter-spacing: -2px;
          color: #001234;
        }

        @media (max-width: 576px) {
          .main-heading {
            font-size: 32px;
            font-weight: 600;
            line-height: 38.4px;
          }

          .main-sub-heading {
            font-size: 20px;
            font-weight: 500;
          }
        }
      `}</style>
    </div>
  );
};

export default HomeEventTicketingIntro;
