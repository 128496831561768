import React, { useState, useEffect, useRef } from 'react';
import revampData from '../../data/revampData.json';
import { theme, websiteColors } from "../../themes/Theme.js";

const HeroFeatureTabs = () => {
  const tabData = revampData.data.listEventFeatureTabs.data;
  const eventFeatureData = revampData.data.listFeatureIntroSections.data;

  const [activeTab, setActiveTab] = useState(tabData[0]);
  const [isHovered, setIsHovered] = useState(false); // State to track hover
  const autoSwitchRef = useRef(); // Ref to store interval ID

  // Handle auto-switching
  useEffect(() => {
    if (!isHovered) {
      autoSwitchRef.current = setInterval(() => {
        setActiveTab((prevTab) => {
          const currentIndex = tabData.findIndex(tab => tab.tabTitle === prevTab.tabTitle);
          const nextIndex = (currentIndex + 1) % tabData.length;
          return tabData[nextIndex];
        });
      }, 3000); // Switch tabs every 3 seconds
    }

    return () => clearInterval(autoSwitchRef.current); // Cleanup interval on component unmount or hover change
  }, [isHovered, tabData]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const breakOnHyphen = (text) => {
    return text.split(/[—]/).map((part, index, array) => (
      <React.Fragment key={index}>
        {part}
        {index < array.length - 1 && (
          <>
            {' '}
            —<br />
          </>
        )}
      </React.Fragment>
    ));
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    clearInterval(autoSwitchRef.current); // Clear interval when hovered
  };

  const handleMouseLeave = () => {
    setIsHovered(false); // Resume auto-switching after hover ends
  };

  const featureArray = Object.keys(activeTab.features)
    .filter((key) => key !== 'featureImage') // Exclude featureImage from the array
    .map((key) => activeTab.features[key]);

  const intros = revampData.data.listEventFeatureIntros.data;

  return (
    <div
      className="px-sm-5 px-3 main-container"

    >
      {/* Intro Section */}
      <div className="text-center px-sm-5 px-3 py-5">
        <h6 className="text-uppercase heading-label mb-2">{intros[2].title}</h6>
        <h2 className="display-5 main-heading">{intros[1].title}</h2>
        <p className="lead main-sub-heading mt-3">{intros[0].title}</p>
      </div>

      {/* Feature Tabs Section */}
      <div className="d-xl-block font-figtree d-none tab-container py-4 feature-tab-container">
        <div className="d-flex align-items-start tab-xl">
          {/* Left Navigation Column */}
          <div
            className="nav flex-column align-items-start nav-pills ticket-nav"
            role="tablist"
            aria-orientation="vertical"
          >
            {tabData.map((tab, index) => (
              <button
                key={index}  // Changed to use index as key
                className={`nav-link ${activeTab.tabTitle === tab.tabTitle ? 'active' : ''}`}
                onClick={() => handleTabClick(tab)}
                onMouseEnter={handleMouseEnter} // Pause on hover
                onMouseLeave={handleMouseLeave} // Resume when hover ends
                style={{ maxWidth: '230px' }}
              >
                <div className="nav-icon-text d-flex align-items-start">
                  <img
                    src={activeTab.tabTitle === tab.tabTitle ? tab.whiteIcon : tab.blackIcon}
                    alt=""
                    className={`tab-icon ${activeTab.tabTitle === tab.tabTitle ? 'active' : ''}`}
                  />
                  <span className="tab-title">{tab.tabTitle}</span>
                </div>
              </button>
            ))}
          </div>

          {/* Right Content Area */}
          <div className="flex-grow-1 d-flex flex-row justify-content-between align-items-center m-auto">
            <div className="ticket-details">
              <h3 className="section-title">{breakOnHyphen(activeTab.sectionTitle)}</h3>
              <ul className="list-unstyled">
                {activeTab.features.map((feature, index) => (
                  <li key={index}>
                    {feature && <><img src="/img/tick.svg" alt="tick" /> {feature}</>}
                  </li>
                ))}
              </ul>
            </div>

            {/* Right Image */}
            <div className="right-container d-flex justify-content-center align-items-center">
              <img
                src={activeTab.featureImage}
                alt="Feature"
                className="tab-img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        /* Container and Background */
        .container {
          width: 100%;
        }

        /* Ticket-like left column */
        .ticket-nav {
          min-width: 290px;
          border-radius: 10px;
          padding-left: 42px;
        }

        .tab-icon {
          margin-right: 10px;
          height: 21px;
          width: 21px;
        }

        .nav-link {
          text-align: left;
          color:${websiteColors[theme].textParagraph};
          background: none;
          border: none;
          display: flex;
          align-items: center;
          border-radius: 4px;
          transition: background-color 0.3s ease;
          margin-bottom: 10px;
          padding: 14px 12px;
        }

        button:focus {
          box-shadow: 0 0 0 !important;
        }

        .nav-link.active {
          background-color: #007bff;
          color: white;
        }

        .nav-icon-text {
        }

        .tab-title {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
        }

        .nav-icon {
          margin-right: 8px;
          width: 20px;
          height: 20px;
        }

        /* Right side flex container */
        .flex-grow-1 {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          max-width: 850px;
          margin-left: 15px;
        }

        .list-unstyled li {
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: -0.36px;
        }

        .ticket-details {
          padding: 20px;
          border-radius: 10px;
          max-width: 390px;
          width: 100%;
        }

        .nav-link.active {
          background-color: ${websiteColors[theme].primaryWebsiteTheme};
          color: white;
        }

        .section-title {
          font-size: 24px;
          margin-bottom: 20px;
          color: #001a47;
          font-weight: 600;
          line-height: 38px;
        }

        ul {
          list-style-type: none;
          padding-left: 0;
          margin-bottom: 20px;
        }

        li {
          font-size: 1rem;
          margin-bottom: 10px;
        }

        .right-container {
          display: flex;
          justify-content: center;
          align-items: center;
          max-height: 284.67px; /* Ensure image is centered */
        }

        .tab-img-fluid {
          max-width: 100%;
          width: auto;
          max-height: 320px; /* Limit the image height */
          object-fit: cover;
          border-radius: 10px;
        }

        /* Constant Background */
        .feature-tab-container {
          background: url('https://d2nnrpx7gk2my2.cloudfront.net/files/66ec27d8171d49000893dee8/Featuresticketdesign(1).webp') no-repeat;
          background-size: contain;
          width: 100%;
          min-height: 615px; /* Ensures minimum height */
        }

        .tab-container {
          width: 100%;
        }

        /* Responsiveness */
        @media (max-width: 768px) {
          .flex-grow-1 {
            flex-direction: column;
          }

          .tab-img-fluid {
            margin-top: 20px;
            max-width: 100%;
            width: auto;
            height: auto;
          }
        }


        /* Typography Adjustments */
        .heading-label {
          color: ${websiteColors[theme].primaryWebsiteTheme};
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }

        .main-sub-heading {
          color: ${websiteColors[theme].textSecondary};
          font-size: 24px;
          font-weight: 400;
          line-height: 30px;
        }

        .main-heading {
          color: #001a47;
          font-size: 48px;
          font-weight: 600;
          line-height: 57.6px;
          letter-spacing: -2px;
        }

        .container {
          max-width: 1140px !important;
          width: 100% !important;
        }

        @media (min-width: 1500px) {
          .tab-xl{
            margin-top:25px;
          }
          .ticket-nav{
            padding-left: 51px;
          }
        }

        @media (min-width: 1440px) {
          .feature-tab-container{  display: flex;
            max-width: 1300px;
            margin: auto;
          }
        }

        @media (min-width: 1315px) {
          .flex-grow-1 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            max-width: 923px;
            margin-left: 25px;
          }
        }

        @media (max-width: 768px) {
          .main-heading {
            font-size: 32px;
            font-weight: 600;
            line-height: 38px;
          }

          .main-sub-heading {
            font-size: 20px;
            font-weight: 500;
          }
        }
      `}</style>
    </div>
  );
};

export default HeroFeatureTabs;