import React, { useState } from 'react';
import revampData from '../../data/revampData.json';
import { theme, websiteColors } from "../../themes/Theme.js";

const HeroFeatureAccordion = () => {
  const tabData = revampData.data.listEventFeatureTabs.data;
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle the accordion item
  };

  return (
    <div className="px-sm-5 px-3 py-sm-5 py-3 mb-4 mb-sm-2 d-block d-xl-none font-figtree">
      <div className="accordion" id="featureAccordion">
        {tabData.map((tab, index) => (
          <div className="accordion-item" key={index}>
            <h2 className="accordion-header" id={`heading${index}`}>
              <button
                className={`accordion-button ${activeIndex === index ? '' : 'collapsed'}`}
                type="button"
                aria-expanded={activeIndex === index ? 'true' : 'false'}
                aria-controls={`collapse${index}`}
                onClick={() => handleAccordionClick(index)}
              >
                <i className="bi bi-chevron-down"></i>
                <img
                  className="tab-icon"
                  src={activeIndex === index ? tab.whiteIcon : tab.blackIcon}
                  alt=""
                />
                {tab.tabTitle}
              </button>
            </h2>
            <div
              id={`collapse${index}`}
              className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
              aria-labelledby={`heading${index}`}
            >
              <div className="accordion-body">
                <h3 className="section-title">{tab.sectionTitle}</h3>
                <ul className="list-unstyled">
                  {tab.features.map((feature, i) => (
                    <li key={i}>
                      <div className="d-flex gap-3">
                        <div>
                          <img src="/img/tick.svg" alt="tick" className="mr-3" />
                        </div>
                        <p>{feature}</p>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="row">
                  <div className="col-md-12">
                    <img
                      src={tab.featureImage}
                      alt={tab.tabTitle}
                      className="img-fluid feature-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <style jsx>{`
        .accordion-button {
          background-color: ${websiteColors[theme].primaryWebsiteTheme};
          color: white;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
        }
        .accordion-button:focus {
          box-shadow: none;
        }

        .accordion-button.collapsed {
          background-color: #fff;
          color: ${websiteColors[theme].textParagraph};
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
        }

        .accordion-body {
          background-color: white;
          border-radius: 10px;
          padding: 20px;
        }

        .accordion-item {
          border: 0px;
        }

        .accordion-button {
          border-radius: 5px !important;
          padding: 14px 12px !important;
        }

        .section-title {
          font-size: 20px;
          margin-bottom: 20px;
          color: #001234;
          font-weight: 600;
          line-height: 30px;
        }

        .list-unstyled li {
          font-size: 16px;
          color: ${websiteColors[theme].textParagraph};
          font-weight: 400;
          line-height: 28px;
        }

        .feature-image {
          max-width: 100%;
          border-radius: 10px;
          margin-top: 15px;
        }

        ul {
          list-style-type: none;
          padding-left: 0;
          margin-bottom: 20px;
        }

        li {
          font-size: 1rem;
          margin-bottom: 10px;
        }

        .tab-icon {
          margin-right: 10px;
          height: 21px;
          width: 21px;
        }

        .accordion-button::after {
          background-image: url('/img/arrow-down-black.svg') !important;
          width: 22px;
          height: 22px;
        }

        .accordion-button:not(.collapsed)::after {
          background-image: url('/img/arrow-down.svg') !important;
          transform: rotate(-180deg);
          width: 22px;
          height: 22px;
        }

        button:focus {
          box-shadow: 0 0 0 !important;
        }
      `}</style>
    </div>
  );
};

export default HeroFeatureAccordion;