import React from 'react';
import revampData from '../../data/revampData.json';
import { theme, websiteColors } from "../../themes/Theme.js";


const HomeEventBrand = () => {
  const customizations = [
    {
      "id": "66df055f49f4bc000893e6a0#0004",
      "title": "Card4",
      "description": "This is a Card4",
      "type": "card_numberFour",
      "features": {
        "title": "AI-Powered and Modern",
        // "subtitle": "Customize sender name and address with your brand, enhancing communication and brand consistency.",
        "featureImage": "https://d2nnrpx7gk2my2.cloudfront.net/files/672e100a630f4c0008f805b9/1.webp"
      }
    },
    {
      "id": "66df0534eb194800083f76d4#0003",
      "title": " Card3",
      "description": "This is a Card1",
      "type": "card_numberThree",
      "features": {
        "title": "Out-of-the-box Whitelabling ",
        // "subtitle": "Prominently display your logo for a professional appearance and brand reinforcement",
        "featureImage": "https://d2nnrpx7gk2my2.cloudfront.net/files/672e100b630f4c0008f805bb/2.webp"
      }
    },
    {
      "id": "66df04fdeb194800083f76d3#0003",
      "title": "Card2",
      "description": "This is a Card2",
      "type": "card_numberTwo",
      "features": {
        "title": "End-to-End Event Tech Solution",
        // "subtitle": "Checkout Page branded with logo, customizable Terms & Conditions text.",
        "featureImage": "https://d2nnrpx7gk2my2.cloudfront.net/files/672e1006630f4c0008f805b5/3.webp"
      }
    },
    {
      "id": "66df04d4eb194800083f76d2#0003",
      "title": "Card1",
      "description": "This is a Card1",
      "type": "card_numberOne",
      "features": {
        "title": "Easy-to-use (Do-It-Yourself) Platform",
        // "subtitle": "Logo & Favicon Branding. Seamlessly replace event logo/favicon for consistent brand identity.",
        "featureImage": "https://d2nnrpx7gk2my2.cloudfront.net/files/672e1008630f4c0008f805b7/4.webp"
      }
    }
  ];

  return (
    <div className="px-sm-5 font-figtree px-3 pb-5">
      <div className="row g-4">
        {customizations.map((card) => (
          <div key={card.id} className="col-md-6 col-lg-3 col-12">
            <div className="custom-card h-100 d-flex flex-column justify-space-between">
              <div className="custom-card-body text-start">
                <h5 className="custom-card-title fw-bold">{card.features.title}</h5>
                <p className="custom-card-subtitle">{card.features.subtitle}</p>
              </div>
              <div
                className="my-3 brand-card"
              >
                <img
                  src={card.features.featureImage}
                  alt={card.features.title}
                  className="img-fluid custom-card-img-top mt-3"
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <style jsx>{`
        .custom-card {
          border: 1px solid #61C0FF;
          border-radius: 10px;
          padding: 23px;
          transition: all 0.3s ease;
          background-color: white;
        }
        .img-fluid{
          height: 100% !important;
          width: 100% !important;
        }
        .brand-card{
         // max-height: 180px !important;
         // max-width: 253px !important;
          margin: auto !important;
        }

        .custom-card:hover {
          //box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
        }

        .custom-card-img-top {
          //max-height: 150px;
          object-fit: contain;
        }

        .custom-card-title {
          font-size: 24px;
          font-weight: 700;
          line-height: 28.8px;
          color: #001a47;
        }

        .custom-card-subtitle {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: ${websiteColors[theme].textParagraph};
        }

      `}</style>
    </div>
  );
};

export default HomeEventBrand;
