import React from 'react';
import revampData from '../../data/revampData.json';
import CompanyCarousel from '../Pricing/CompanyCarousel';

const HomePartners = () => {
  const companies = revampData.data.listTrustedCompanies.data;

  return (
    <div>
      <div className="px-sm-5 font-figtree px-md-3 px-3 text-center pt-5">
        <p className="mb-4 fw-semibold font-sm" style={{ color: '#002E6E' }}>
          {companies[10]?.title || "Trusted by 1000+ Companies & Communities"}
        </p>
        <div className="row logo-container justify-content-center align-items-center company-row">
          {/* {companies.slice(1).map((company) => (
            <div className="col-4 col-md-2 mb-3 d-flex justify-content-center align-items-center" key={company.id}>
              <img
                src={company.image}
                alt={company.description}
                className="img-fluid partner-logo"
              />
            </div>
          ))} */}
          <CompanyCarousel isPricingPage={false} />
        </div>
      </div>

      <style jsx>{`
        .partner-logo {
          max-height: 60px;
          max-width: 120px;
          object-fit: contain;
          filter: grayscale(100%);
          transition: filter 0.3s ease;
        }
        .partner-logo:hover {
          filter: grayscale(0%);
        }
        .logo-container {
          max-width: 900px;
          margin: auto;
          width: 100%;
        }
        @media (min-width: 1024px) {
        .company-row {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-around;
        }
      }
        @media (max-width: 373px) {
        .company-row {
          display: flex;
          flex-wrap: wrap;
          gap:15px;
          justify-content: space-around;
          }
        }
      `}</style>
    </div>
  );
};

export default HomePartners;